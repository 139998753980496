import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"

const Contact = () => (
  <Layout pageInfo={{ pageName: "kindercoaching", pageTitle: "Kindercoaching" }}>
    <Seo title="Contact"/>

    <Container>
      <section className="general-page" id="contact">
        <Container>
          <Row>
            <Col>
              <div className="block">
                <p>
                  Naast Wondere Wereld van Tekeningen heb ik ook een eigen kind- en gezinscoachingspraktijk: Alles Kids
                  Kindercoaching. Kindercoaching kan zonder verwijzing van de huisarts en is geen therapie: het is een
                  laagdrempelige, kortdurende en praktische begeleiding voor kinderen en jeugdigen tot 18 jaar. De
                  belevingswereld van een kind staat hierbij centraal. Er wordt op een positieve en oplossingsgerichte
                  manier gewerkt aan onder meer het zelfvertrouwen en de zelfkennis van het kind. Natuurlijk spelen
                  ouders
                  of verzorgers hierbij ook een grote rol! Ouders of verzorgers worden dan ook betrokken in het traject
                  en
                  krijgen handvaten aangereikt om hun kind verder te kunnen begeleiden naar volwassenheid.
                </p>

                <p>
                  Ook binnen coachingstrajecten gebruik ik veelvuldig de methodiek van het analyseren van
                  kindertekeningen. De informatie die ik hierin vindt vormt een goed uitgangspunt voor de verdere
                  begeleiding! Meer informatie over kind- en gezinscoaching is te vinden op <a
                  href="https://www.alleskidskindercoaching.nl">www.alleskidskindercoaching.nl</a>
                </p>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Container>
  </Layout>
)

export default Contact
